import React, { useState, useEffect } from 'react';
import { get, post,put, del } from '../services/ApiService';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BsFillTrash3Fill,BsPencilSquare,BsSearch,BsXCircle,BsCheckCircle   } from "react-icons/bs";
import Select from 'react-select';
const BASE_URL = process.env.BASE_URL;

const TableSku = ({ onAddItem, onUpdateItem, onDeleteItem }) => {
  const [data, setData] = useState([]);
  const [missingSku, setMissingSku] = useState([]);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [sortDirection, setSortDirection] = useState('asc');
  const [searchFilters, setSearchFilters] = useState({});
  const [showCalendar, setShowCalendar] = useState(false);
  const [searchIconClicked, setSearchIconClicked] = useState(false);
  const [itemOptions, setItemOptions] = useState([]);
  const [newItem, setNewItem] = useState({
        name : '',
        sku : '',
        id : '',
        quantity : '',
        classification : '',
        channel_ref  : ''
  });
  const [updateItem, setUpdateItem] = useState({
    name : '',
    sku : '',
    id : '',
    quantity : '',
    classification : '',
    channel_ref  : '',
     auto_id: ''
  });
  const [selectedItemId, setSelectedItemId] = useState(null);
    const [showFilterPopover, setShowFilterPopover] = useState(false);
  const [filterColumn, setFilterColumn] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchSkuData(); // Fetch initial data on component mount
    fetchSkuMissingData(); // Fetch item options
    fetchPipData();
  }, []);

  const fetchSkuData = async () => {
    try {
      const response = await get('api/sku/skupk');
      const newOptions = response.data.map((item) => ({ product: item.product, id: item.id }));
      const sortedData = response.data;
      setData(sortedData);

      console.log(data);

    } catch (error) {
      console.error('Error fetching ppid:', error);
    }
  };

  const fetchSkuMissingData = async () => {
    try {
      const response = await get('api/sku/missingsku');
      const newOptions = response.data.map((item) => ({ product: item.product, id: item.id }));
      setMissingSku(response.data);
    } catch (error) {
      console.error('Error fetching ppid:', error);
    }
  };

  const fetchPipData = async () => {
    try {
      const response = await get('api/ppidProductInfo/ppid');
      const newOptions = response.data.map((item) => ({ product: item.product, id: item.id }));
      setItemOptions(newOptions);
    } catch (error) {
      console.error('Error fetching ppid:', error);
    }
  };

  const handleSort = (column) => {
    if (sortedColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedColumn(column);
      setSortDirection('asc');
    }
  };

  const handleSearch = (e, column) => {
    const { value } = e.target;
    setSearchFilters(prevFilters => ({ ...prevFilters, [column]: value }));
  };

  const handleColumnHeaderClick = (columnKey) => {
    if (!searchIconClicked) {
      console.log(sortedColumn)

      handleSort(columnKey);
    }
  };
  

  const handleSearchIconClick = (columnKey) => {
    // setSortedColumn(null); // Reset sorting
    setFilterColumn(columnKey);
    setShowFilterPopover(true);
    setShowCalendar(columnKey === 'date');
    setSearchIconClicked(true); // Set search icon clicked
  };

  const handleFilterPopoverClose = () => {
    setShowFilterPopover(false);
    setSearchIconClicked(false); // Reset search icon clicked
    setSearchFilters('');
  };

  const handleDateSelect = (date) => {
    const formattedDate = date.toISOString().split('T')[0];
    setSearchFilters(prevFilters => ({ ...prevFilters, date: formattedDate }));
    setShowCalendar(false); // Hide the calendar after selection
  };

  const filteredData = data.filter(item => {
    return Object.entries(searchFilters).every(([key, value]) => {
      if (!value || key === 'auto_id') return true; // Exclude 'id' from search
      if (key === 'date') {
        const itemDate = new Date(item[key]).toISOString().split('T')[0];
        const searchDate = new Date(value).toISOString().split('T')[0];
        return itemDate.includes(searchDate);
      }
      return String(item[key]).toLowerCase().includes(value.toLowerCase());
    });
  });

  const sortedData = sortedColumn
    ? filteredData.slice().sort((a, b) => {
      const valueA = a[sortedColumn];
      const valueB = b[sortedColumn];
      if (sortedColumn === 'date') {
        return sortDirection === 'asc' ? new Date(valueA) - new Date(valueB) : new Date(valueB) - new Date(valueA);
      }
      if (valueA < valueB) return sortDirection === 'asc' ? -1 : 1;
      if (valueA > valueB) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    })
    : filteredData.slice();


  const handleAddItem = async () => {

    try {

    const cleansedNewItem = {
        ...newItem,
        id: parseInt(newItem.ppid),
        quantity: parseInt(newItem.quantity)
      };

      console.log(cleansedNewItem)

      if (!Number.isInteger(cleansedNewItem.quantity)) {
        console.warn('Order Quantity and Approved Quantity must be integers');
      }

      if(!cleansedNewItem.quantity || cleansedNewItem.quantity=='' || !cleansedNewItem.id || cleansedNewItem.id=='' || !cleansedNewItem.classification || cleansedNewItem.classification=='' || !cleansedNewItem.sku || cleansedNewItem.sku=='' || !cleansedNewItem.name || cleansedNewItem.name==''){
        alert('Ensure all info is added');
        return
      }

      await post('api/sku', cleansedNewItem);

      setNewItem({
        name : '',
        sku : '',
        id : '',
        quantity : '',
        classification : '',
        channel_ref  : ''
      });
      fetchSkuData();
      fetchSkuMissingData();

      onAddItem();
    } catch (error) {
        console.error('Error adding stock loss:', error);
      }
};
  const handleUpdateItem = async () => {

    try {
        
    const cleansedUpdateItem = {
        ...updateItem,
        id: updateItem.ppid ? parseInt(updateItem.ppid) : parseInt(updateItem.id),
        quantity: parseInt(updateItem.quantity)

      };
      console.log(cleansedUpdateItem)

      if (!Number.isInteger(cleansedUpdateItem.quantity)) {
        console.warn('Order Quantity and Approved Quantity must be integers');
      }


      if(!cleansedUpdateItem.quantity || cleansedUpdateItem.quantity=='' || !cleansedUpdateItem.id || cleansedUpdateItem.id=='' || !cleansedUpdateItem.classification || cleansedUpdateItem.classification=='' || !cleansedUpdateItem.sku || cleansedUpdateItem.sku=='' || !cleansedUpdateItem.name || cleansedUpdateItem.name==''){
        alert('Ensure all info is added');
        return
      }

      console.log(cleansedUpdateItem)

      await put(`api/sku/${cleansedUpdateItem.auto_id}`, cleansedUpdateItem);
      setSelectedItemId(null);
      setUpdateItem({
        name : '',
        sku : '',
        id : '',
        quantity : '',
        classification : '',
        channel_ref  : '',
        auto_id: ''
      });
      fetchSkuData();
      fetchSkuMissingData();
      onUpdateItem();
    } catch (error) {
        console.error('Error adding invoice:', error);
      }
};

const handleDeleteItem = async (id) => {
    try {
      // Display confirmation dialog
      const isConfirmed = window.confirm("Are you sure you want to delete this item?");

      if (isConfirmed) {
        await del(`api/sku/${id}`);
        fetchSkuData(); // Refresh data after deleting
        fetchSkuMissingData();
        onDeleteItem(); // Notify parent component about the deletion
      }
    } catch (error) {
      console.error('Error deleting invoice:', error);
    }
  };


  const handleUpdateButtonClick = (item) => {
    setSelectedItemId(item.auto_id);
    setUpdateItem({ ...item }); // Ensure 'updateItem' is populated correctly
    //console.log(updateItem);
  };

  const handleCloseUpdateItem = () => {
    setSelectedItemId(null);
    setUpdateItem({
      name : '',
      sku : '',
      id : '',
      quantity : '',
      classification : '',
      channel_ref  : '',
      auto_id: ''
    });

  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '15px',
      color: 'black',
      padding: '5px',
      borderWidth: '0px',
      width: '500px'
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 0, // remove border radius
    }),
    option: (provided, state) => ({
      ...provided,
      width: '500px',
      fontSize: '14px'
      // backgroundColor: state.isSelected ? '#007bff' : 'transparent', // highlight selected option
      // color: state.isSelected ? '#fff' : '#000', // text color for selected and non-selected options
    }),
  };


  return (
  
    <div>
    <h2><b>Product SKUs</b></h2>

<div className="add-invoice">
  <button className="add-invoice-button" onClick={() => setShowPopup(true)}>
    + Add New SKU
  </button>
</div>

{showPopup && (
  <div className="popup">
    <div className="popup-inner">
      <div className="update-form">
        {Object.keys(newItem).map((key) => (
          <div className="form-group" key={key}>
 {key === 'id' ? (

<div className="field-container">
             <label className='form-labels'>
                   
             Item:
             </label >

<div className="select-dropdown">
<Select
value={
newItem.item_name && newItem.ppid
  ? { value: `${newItem.ppid} - ${newItem.item_name}`, label: `pp${newItem.ppid} - ${newItem.item_name}` }
  : { value: "", label: "Select item" }
}
onChange={(selectedOption) => setNewItem({ ...newItem, item_name: selectedOption.item_name, ppid: selectedOption.ppid })}
options={itemOptions.map(item => ({ value: `${item.id} - ${item.product}`, label: `pp${item.id} - ${item.product}`, item_name: item.product, ppid: item.id }))}
styles={customStyles}
/>

</div>
</div>


) : key === 'classification' ? (<div className="field-container">
  <label className='form-labels'>
        
  Classification:
  </label >

<div className="select-dropdown">
<Select
value={
newItem.classification
? { value: newItem.classification, label: newItem.classification }
: { value: "", label: "Select Classification" }
}
onChange={(selectedOption) =>
setNewItem({ ...newItem, classification: selectedOption.value })
}
options={[
{ value: "GSL", label: "GSL" },
{ value: "P", label: "P" },
{ value: "POM", label: "POM" }
]}
styles={customStyles}
/>


</div>
</div>) : key !== 'ppid' ? (
<div className="field-container">
<label className='form-labels'>{key}:</label>
<input className='form-fields'
  type={key === 'date' ? 'date' : key === 'quantity' ? 'number' : 'text'}
  step={key === 'quantity' ? '1' : 'any'}
  value={newItem[key]}
  onChange={(e) => setNewItem({ ...newItem, [key]: e.target.value })}
/>
</div>
            ) : null}
          </div>
        ))}
        <button className="update-button" onClick={handleAddItem}>
         <BsCheckCircle />
        </button>
        <button className="close-button" onClick={() => setShowPopup(false)}>
       < BsXCircle />
      </button>
      </div>
    </div>
  </div>
)}
<div className='table_back'>
    <table>
    <thead>
          <tr>
            {data.length > 0 &&
              Object.keys(data[0]).map((key) =>
                key !== 'auto_id' ? (
<th key={key} >
  <div onClick={() => handleColumnHeaderClick(key)}>
  {key}{' '}
  {!searchIconClicked && sortedColumn === key && (sortDirection === 'asc' ? '▲' : '▼')}

  </div>

  <div>
  
  <button className="search-icon" onClick={() => handleSearchIconClick(key)}>
    < BsSearch  className="icon-search"/>
    </button>

  </div>


  {filterColumn === key && showFilterPopover && (
    <div className="popover">
      {key !== 'date' ? (
        <input
          type="text"
          placeholder={`Search ${key}`}
          value={searchFilters[key] || ''}
          onChange={(e) => handleSearch(e, key)}
        />
      ) : (
        <DatePicker
          selected={searchFilters.date ? new Date(searchFilters.date) : null}
          onChange={handleDateSelect}
          dateFormat="yyyy-MM-dd"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
      )}
      <button onClick={handleFilterPopoverClose}><BsXCircle className="icon-search"/></button>
    </div>
  )}
</th>
                ) : null
              )}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item) => (
            <React.Fragment key={item.auto_id}>
              <tr>
                {Object.keys(item).map((key) =>
                  key !== 'auto_id' ? (
                    <td key={key}>{key === 'date' ? new Date(item[key]).toISOString().split('T')[0] : item[key]}</td>
                  ) : null
                )}
                <td>
                  <button className="action-button" onClick={() => handleUpdateButtonClick(item)}>
                  <BsPencilSquare />
                  </button>
                  <button className="action-button" onClick={() => handleDeleteItem(item.auto_id)}>
                  <BsFillTrash3Fill />
                  </button>
                </td>
              </tr>
              {selectedItemId === item.auto_id && (
  <tr>
    <td colSpan={Object.keys(item).length}>
      <div>
        <h3>Update</h3>
        <div className="update-form">
        {Object.keys(updateItem).map((key) => (
          <div className="form-group" key={key}>
 {key === 'id' ? (

<div className="field-container">
             <label className='form-labels'>
                   
             Item:
             </label >

<div className="select-dropdown">
<Select
value={
  updateItem.name && updateItem.id
  ? { value: `${updateItem.id} - ${updateItem.name}`, label: `pp${updateItem.id} - ${updateItem.name}` }
  : { value: "", label: "Select item" }
}
onChange={(selectedOption) => setUpdateItem({ ...updateItem, item_name: selectedOption.item_name, ppid: selectedOption.ppid })}
options={itemOptions.map(item => ({ value: `${item.id} - ${item.product}`, label: `pp${item.id} - ${item.product}`, item_name: item.product, ppid: item.id }))}
styles={customStyles}
/>

</div>
</div>


) : key === 'classification' ? (<div className="field-container">
  <label className='form-labels'>
        
  Classification:
  </label >

<div className="select-dropdown">
<Select
value={
  updateItem.classification
? { value: updateItem.classification, label: updateItem.classification }
: { value: "", label: "Select Classification" }
}
onChange={(selectedOption) =>
setUpdateItem({ ...updateItem, classification: selectedOption.value })
}
options={[
{ value: "GSL", label: "GSL" },
{ value: "P", label: "P" },
{ value: "POM", label: "POM" }
]}
styles={customStyles}
/>


</div>
</div>) : key !== 'auto_id' ? (
<div className="field-container">
<label className='form-labels'>{key}:</label>
<input className='form-fields'
  type={key === 'date' ? 'date' : key === 'quantity' ? 'number' : 'text'}
  step={key === 'quantity' ? '1' : 'any'}
  value={updateItem[key]}
  onChange={(e) => setUpdateItem({ ...updateItem, [key]: e.target.value })}
/>
</div>
            ) : null}
          </div>
        ))}
                        <button className="update-button" onClick={handleUpdateItem}>
                        <BsCheckCircle />
                        </button>
                        <button className="close-button" onClick={handleCloseUpdateItem}>
                        < BsXCircle />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
    </div>

  );
};

export default TableSku;
